<template>
  <div class="ccpa_consent">
    <div
      :class="['ccpa_checkbox', consented === true ? 'checked' : '']"
      @touchend="toggleConsent($event)"
      @click="toggleConsent($event)"
    />
    <div class="dns">
      {{ content.ccpa_dns }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Consent',
  props: {
    default: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      consented: false,
      content: {},
    }
  },
  mounted() {
    this.content = this.$compliance.getLocalisedContent()
    this.update(this.$compliance.CCPA.getoptout())
  },
  methods: {
    update(consent) {
      this.consented = consent
    },
    toggleConsent(event) {
      if (event.handled === false) return
      event.stopPropagation()
      event.preventDefault()
      event.handled = true
      this.consented = !this.consented

      this.$compliance.CCPA.setoptout(this.consented)
    },
  },
}
</script>

<style lang="scss">
.ccpa_consent {
  display: flex;
  margin: 20px 20px 8px 20px;
  align-items: center;

  .ccpa_checkbox {
    background-image: url('../../assets/images/btn_checkbox_empty.png');
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999px;
    width: 40px;
    min-width: 40px;
    height: 30px;
    margin-left: auto;
    @media screen and (min-width: 768px) {
      width: 50px;
      height: 40px;
      flex-basis: 50px;
    }
    &.checked {
      background-image: url('../../assets/images/btn_checkbox.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .dns {
    color: #4c5d6a;
    font-size: 1.1em;
    line-height: 1.3em;
    margin-right: auto;
  }
}
.landscape .ccpa_consent .ccpa_checkbox {
  width: 50px;
  height: 40px;
  flex-basis: 50px;
}
.landscape .ccpa_consent .dns {
  line-height: 2.4em;
}
</style>
