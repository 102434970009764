// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/btn_checkbox_empty.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/btn_checkbox.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".ccpa_consent{display:flex;margin:20px 20px 8px 20px;align-items:center}.ccpa_consent .ccpa_checkbox{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;text-indent:-9999px;width:40px;min-width:40px;height:30px;margin-left:auto}@media screen and (min-width:768px){.ccpa_consent .ccpa_checkbox{width:50px;height:40px;flex-basis:50px}}.ccpa_consent .ccpa_checkbox.checked{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:contain;background-repeat:no-repeat}.ccpa_consent .dns{color:#4c5d6a;font-size:1.1em;line-height:1.3em;margin-right:auto}.landscape .ccpa_consent .ccpa_checkbox{width:50px;height:40px;flex-basis:50px}.landscape .ccpa_consent .dns{line-height:2.4em}", ""]);
// Exports
module.exports = exports;
